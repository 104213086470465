import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import { Container, Gallery } from '../components/styled';
import Layout from '../components/layout';

const DualContainer = styled(Container)`
  max-width: 70%;
  margin: auto;
  display: flex;
  @media (max-width: 780px) {
    max-width: 90%;
    flex-direction: column;
  }
  @media (max-width: 1240px) {
    max-width: 90%;
  }
  > div {
    flex: 1;
    min-width: 50%;
    padding: 0% 2% 0%;
  }
  ul {
    list-style: none;
    li::before {
      content: '•';
      color: #c69300;
      left: -10px;
      position: relative;
    }
  }
`;

const JuwelierPage = ({ data }) => (
  <Layout>
    <DualContainer>
      <div>
        <h1>{data.markdownRemark.frontmatter.leftTitle}</h1>
        <div>{data.markdownRemark.frontmatter.leftInfo}</div>
        <Gallery>
          <Slider slidesToShow={1}>
            {data.markdownRemark.frontmatter.images.map(img => (
              <Img
                fixed={img.childImageSharp.fixed}
                imgStyle={{ height: 'auto', width: '100%' }}
              />
            ))}
          </Slider>
        </Gallery>
        <div>{data.markdownRemark.frontmatter.leftDetails}</div>
      </div>
      <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
    </DualContainer>
  </Layout>
);

export const query = graphql`
  query JuwelierPageQuery($id: String) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        leftTitle
        leftInfo
        images: leftImages {
          childImageSharp {
            fixed(width: 500, height: 350, cropFocus: CENTER) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        leftDetails
      }
    }
  }
`;

export default JuwelierPage;
